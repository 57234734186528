<template>
  <div class="content">
    <PageHeader :title="$t('verify-number.header')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body block-el p-0">
              <p class="p-20">
                {{$t('verify-number.description')}}
              </p>
              <form class="form-horizontal" @submit.prevent="lookup">
                <div class="form-body">
                  <div class="form-group row p-3">
                    <label class="control-label text-center text-md-right col-md-4"
                      >{{$t('verify-number.header')}}</label
                    >
                    <div class="col-md-8 text-center text-md-left">
                      <div class="inner-input">
                        <tel-input ref="mobile_number" v-model="form.number" />
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="offset-md-4 col-md-2 text-center text-md-left">
                      <button
                        :class="{
                          'qt-loader qt-loader-mini qt-loader-right': isSending,
                        }"
                        :disabled="isSending"
                        type="submit"
                        class="btn btn-primary"
                      >
                        {{$t('generic-str.verify')}}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div v-if="number" class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">Resultado</div>
            <div class="card-body block-el">
              <form action="#" class="form-horizontal">
                <div class="form-body">
                  <div class="form-group row">
                    <label class="control-label text-right col-md-4 weight-500"
                      >NÚMERO DE CELULAR</label
                    >
                    <label class="control-label text-left col-md-8">{{
                      number.number
                    }}</label>
                  </div>
                  <div class="form-group row">
                    <label class="control-label text-right col-md-4 weight-500"
                      >FORMATO NACIONAL</label
                    >
                    <label class="control-label text-left col-md-8">{{
                      number.national_format
                    }}</label>
                  </div>
                  <div class="form-group row">
                    <label class="control-label text-right col-md-4 weight-500"
                      >CÓDIGO DO PAÍS</label
                    >
                    <label class="control-label text-left col-md-8">{{
                      number.country_code
                    }}</label>
                  </div>
                  <div class="form-group row">
                    <label class="control-label text-right col-md-4 weight-500"
                      >OPERADORA</label
                    >
                    <label class="control-label text-left col-md-8">{{
                      number.carrier
                    }}</label>
                  </div>
                  <div class="form-group row">
                    <label class="control-label text-right col-md-4 weight-500"
                      >{{$t('generic-str.status.lbl-ported')}}</label
                    >
                    <label
                      v-if="number.ported"
                      class="control-label text-left col-md-8"
                      >{{$t('generic-str.yes')}}</label
                    >
                    <label v-else class="control-label text-left col-md-8"
                      >{{$t('generic-str.no')}}</label
                    >
                  </div>
                  <div class="form-group row">
                    <label class="control-label text-right col-md-4 weight-500"
                      >STATUS</label
                    >
                    <label class="control-label text-left col-md-8">
                      <span
                        v-if="number.status == 'active'"
                        class="badge badge-success"
                        >{{$t('generic-str.status.lbl-active')}}</span
                      >
                      <span v-else class="badge badge-danger">{{$t('generic-str.status.lbl-inactive')}}</span>
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LookupService from '@/services/lookup.service';
import PageHeader from '@/components/PageHeader.vue';
import TelInput from '@/components/form/TelInput.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    TelInput,
  },
  data() {
    return {
      isSending: false,
      form: {
        number: '',
      },
      number: null,
      imgName: '',
      imgExtension: '',
      imgUrl: '',
      image: {},
    };
  },
  methods: {
    callUpload() {
      this.$refs.uploadMenu.toggle();
    },
    uploaded() {
      console.log('Salvo');
    },
    lookup() {
      if (this.$refs.mobile_number.isValid) {
        this.isSending = true;
        LookupService.checkNumber(this.form).then(
          (number) => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: 'Número verificado',
              type: 'success',
            });
            this.number = number;
            this.isSending = false;
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        );
      } else {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: 'Celular inválido',
          type: 'danger',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.inner-input {
  display: inline-block !important;
  min-width: 320px !important;
}

@media (max-width: 960px) {
  .inner-input {
    margin: 0;
  }
}
</style>
