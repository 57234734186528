import axios from '@/plugins/axios';

class SmsService {
  checkNumber(data) {
    this.data = data;
    return axios().post('lookup/numbers', this.data).then((response) => response.data);
  }

  getCheckedNumbers(data) {
    this.data = data;
    return axios().get('lookup/numbers', this.data).then((response) => response.data);
  }
}

export default new SmsService();
